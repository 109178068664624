<template>
  <div class="infoWrap">
    <el-row>
      <el-col :span="12" class="itemWrap">
        <el-col :span="8" class="leftItem">姓名</el-col>
        <el-col :span="16" class="rightItem">{{ studyInfos.name }}</el-col>
      </el-col>
      <el-col :span="12" class="itemWrap">
        <el-col :span="8" class="leftItem">学号</el-col>
        <el-col :span="16" class="rightItem">{{ studyInfos.job_num }}</el-col>
      </el-col>
      <el-col :span="12" class="itemWrap">
        <el-col :span="8" class="leftItem">唯一码</el-col>
        <el-col :span="16" class="rightItem">{{ studyInfos.account_name}}</el-col>
      </el-col>
      <el-col :span="12" class="itemWrap">
        <el-col :span="8" class="leftItem">性别</el-col>
        <el-col :span="16" class="rightItem">{{ studyInfos.sex_name }}</el-col>
      </el-col>

      <el-col :span="12" class="itemWrap">
        <el-col :span="8" class="leftItem">民族</el-col>
        <el-col :span="16" class="rightItem">{{
          studyInfos.ethnic_name
        }}</el-col>
      </el-col>
      <el-col :span="12" class="itemWrap">
        <el-col :span="8" class="leftItem">年级</el-col>
        <el-col :span="16" class="rightItem">{{
          studyInfos.enter_year
        }}</el-col>
      </el-col>
      <el-col :span="12" class="itemWrap">
        <el-col :span="8" class="leftItem">院系</el-col>
        <el-col :span="16" class="rightItem">{{
          studyInfos.faculty_name
        }}</el-col>
      </el-col>
      <el-col :span="12" class="itemWrap">
        <el-col :span="8" class="leftItem">专业/专业大类</el-col>
        <el-col :span="16" class="rightItem">{{
          studyInfos.major_name
        }}</el-col>
      </el-col>
      <el-col :span="24" class="itemWrap">
        <el-col :span="4" class="leftItem">培养层次</el-col>
        <el-col :span="20" class="rightItem">{{
          studyInfos.training_name
        }}</el-col>
      </el-col>
      <el-col :span="24" class="itemWrap">
        <el-col
          :span="4"
          class="leftItem"
          style="height: 40px; line-height: 40px"
          >修改头像</el-col
        >
        <el-col :span="20" class="rightItem">
          <img :src="studyInfos.head_img?studyInfos.head_img:require('@/assets/images/head_default.png')" alt="" />
          <el-button type="text" @click="changeAvatar">修改&nbsp;></el-button>
        </el-col>
      </el-col>
    </el-row>
    <InfoAvatar ref="InfoAvatar"></InfoAvatar>
  </div>
</template>

<script>
import InfoAvatar from "./com/infoAvatar.vue";
import { getstudentinfos } from "../assets/api";

export default {
  components: {
    InfoAvatar,
  },
  data() {
    return {
      studyInfos: {},
    };
  },
  mounted() {
    // this.studyInfos = JSON.parse(sessionStorage.getItem("infos"));
    this.getInfo();
  },
  methods: {
    getInfo() {
      getstudentinfos().then((res) => {
        if (res.code == 0) {
          // sessionStorage.setItem("infos", JSON.stringify(res.data));
          this.$addStorageEvent(2, "infos", JSON.stringify(res.data));
          this.studyInfos = res.data;
        }
      });
    },
    changeAvatar() {
      this.$refs.InfoAvatar.openInit();
    },
  },
};
</script>

<style scoped lang="scss">
.infoWrap {
  background: #fff;
  border-radius: 8px;
  padding:40px 20px 20px;
  box-sizing: border-box;
  height: 100%;
  .itemWrap {
    font-size: 14px;
    height: 40px;
    line-height: 20px;
    margin-bottom: 10px;
    .leftItem {
      text-align: right;
    }
    .rightItem {
      padding-left: 35px;
      img {
        display: inline-block;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        margin-right: 10px;
      }
    }
  }
}
</style>
