<template>
  <div class="avatarWrap">
    <el-dialog title="修改头像" width="700px" :visible.sync="dialogVisible">
      <div>
        <div
          v-for="(item, index) in imgList"
          :key="index"
          class="imgWrap"
          @click="chooseImg(item)"
        >
          <img :src="item.url" alt="" class="allImg" />
          <div class="chooseImg" v-if="chooseItem == item.id">
            <img src="../../assets/images/chooseImg.png" alt="" />
          </div>
        </div>
      </div>
      <div class="btnWrap">
        <el-button type="primary" round @click="submit">确定</el-button>
        <el-button type="info" round @click="dialogVisible = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { updateuserhead } from "../../assets/api.js";
export default {
  data() {
    return {
      dialogVisible: false,
      chooseItem: "",
      imgList: [
        {
          id: "01",
          url: require("../../../../../assets/images/01.png"),
        },
        {
          id: "02",
          url: require("../../../../../assets/images/02.png"),
        },
        {
          id: "03",
          url: require("../../../../../assets/images/03.png"),
        },
        {
          id: "04",
          url: require("../../../../../assets/images/04.png"),
        },
        {
          id: "05",
          url: require("../../../../../assets/images/05.png"),
        },
        {
          id: "06",
          url: require("../../../../../assets/images/06.png"),
        },
        {
          id: "07",
          url: require("../../../../../assets/images/07.png"),
        },
        {
          id: "08",
          url: require("../../../../../assets/images/08.png"),
        },
        {
          id: "09",
          url: require("../../../../../assets/images/09.png"),
        },
        {
          id: "10",
          url: require("../../../../../assets/images/10.png"),
        },
        {
          id: "11",
          url: require("../../../../../assets/images/11.png"),
        },
        {
          id: "12",
          url: require("../../../../../assets/images/12.png"),
        },
        {
          id: "13",
          url: require("../../../../../assets/images/13.png"),
        },
        {
          id: "14",
          url: require("../../../../../assets/images/14.png"),
        },
        {
          id: "15",
          url: require("../../../../../assets/images/15.png"),
        },
        {
          id: "16",
          url: require("../../../../../assets/images/16.png"),
        },
        {
          id: "17",
          url: require("../../../../../assets/images/17.png"),
        },
        {
          id: "18",
          url: require("../../../../../assets/images/18.png"),
        },
        {
          id: "19",
          url: require("../../../../../assets/images/19.png"),
        },
        {
          id: "20",
          url: require("../../../../../assets/images/20.png"),
        },
      ],
    };
  },
  mounted() {},
  methods: {
    openInit() {
      this.dialogVisible = true;
      this.chooseItem = "";
    },
    chooseImg(item) {
      this.chooseItem = item.id;
    },
    submit() {
      let params = {
        head_portrait: this.chooseItem + ".png",
      };
      updateuserhead(params).then((res) => {
        if (res.code == 0) {
          this.dialogVisible = false;
          this.$parent.getInfo();
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.avatarWrap {
  /deep/.el-dialog {
    border-radius: 16px;
    .el-dialog__header {
      border-bottom: 1px solid #eeeeee;
      padding: 20px;
      .el-dialog__title {
        font-weight: bold;
        font-size: 16px;
      }
      i {
        font-size: 16px;
      }
    }
    .el-dialog__body {
      padding: 10px 110px 30px;
    }
  }
  .imgWrap {
    display: inline-block;
    position: relative;
    .allImg {
      display: inline-block;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      margin: 20px;
      cursor: pointer;
    }
    .chooseImg {
      position: absolute;
      left: 20px;
      bottom: 20px;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      img {
        display: inline-block;
        width: 70px;
        height: 70px;
        border-radius: 50%;
      }
    }
  }

  .btnWrap {
    margin-top: 30px;
    text-align: center;
    .el-button.is-round {
      padding: 12px 45px;
    }
  }
}
</style>
